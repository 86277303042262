import React, { FC, useCallback, useMemo, useState } from "react";
import {
  TApplicationEditFormTabProps,
  TApplicationFormValues
} from "../../ApplicationEditForm";
import {
  Button,
  Empty,
  Form,
  FormListFieldData,
  Input,
  Select,
  Spin,
  Table,
  TableColumnsType
} from "antd";
import {
  CTABlock,
  NavButton,
  TableScrollWrapper,
  TableWrapper
} from "../../ApplicationEditForm.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  applicationPositionsActions,
  applicationPositionsSelectors
} from "store/applications/positions";
import { getPositions } from "store/applications/positions/thunk";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import {
  applicationPositionCategoryIdOptions,
  applicationPositionUnitTypeIdOptions
} from "constants/options";

export const PositionsTab: FC<TApplicationEditFormTabProps> = ({
  formData,
  onSubmitStep,
  onPrevStep
}) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm<TApplicationFormValues>();
  const [search, setSearch] = useState("");

  const { positions, isLoading } = useAppSelector(
    applicationPositionsSelectors.getState
  );

  const initialValues: TApplicationFormValues = useMemo(() => {
    const { positions } = formData;

    return {
      positions: positions?.map((position) => ({
        ...position,
        unit_amount: position?.unit_amount?.toString()?.replaceAll(".", ",")
      }))
    };
  }, [formData]);

  const positionsOptions = useMemo(
    () => [
      ...(search ? [{ label: search, value: search }] : []),
      ...(positions || []).map((position) => ({
        label: position?.resource_name,
        value: position?.resource_name
      }))
    ],
    [positions, search]
  );

  const onSubmitHandler = useCallback(
    (application: TApplicationFormValues) => {
      const { positions } = application;
      onSubmitStep({
        positions: positions?.map((position) => ({
          ...position,
          unit_amount: Number(position?.unit_amount?.replaceAll(",", "."))
        }))
      });
    },
    [onSubmitStep]
  );

  const onPositionSearch = useCallback(
    (search: string) => {
      setSearch(search);
      search && dispatch(getPositions({ search }));
    },
    [dispatch]
  );

  const onSelectBlur = useCallback(() => {
    setSearch("");
    dispatch(applicationPositionsActions.clearState());
  }, [dispatch]);

  const onPositionChange = useCallback(
    (positionName: string, name: number) => {
      const position = positions?.find(
        (item) => item?.resource_name === positionName
      );
      form.setFieldValue(
        ["positions", name, "unit_type_id"],
        position?.unit_type_id
      );
      form.setFieldValue(
        ["positions", name, "category_id"],
        position?.parent_id
      );
      form.setFieldValue(
        ["positions", name, "resource_code_id"],
        position?.resource_id
      );
    },
    [form, positions]
  );

  const onClearResourceCode = useCallback(
    (name: number) => {
      form.setFieldValue(["positions", name, "resource_code_id"], undefined);
    },
    [form]
  );
  const onUnitAmountChange = useCallback(
    (value: string, name: number) => {
      form.setFieldValue(
        ["positions", name, "unit_amount"],
        value?.replaceAll(".", ",")
      );
    },
    [form]
  );

  return (
    <>
      <Form
        name="application_edit_positions"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.List
          name="positions"
          rules={[
            {
              validator: async (_, positions) => {
                if (!positions || !positions.length) {
                  return Promise.reject(new Error("Добавьте позиции"));
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            const columns: TableColumnsType<FormListFieldData> = [
              {
                title: "№",
                key: "index",
                render: (value, record, index) => index + 1,
                minWidth: 64
              },
              {
                title: "Наименование",
                key: "position_name",
                render: ({ key, name }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "position_name"]}
                    style={{ width: 350 }}
                    rules={[
                      {
                        required: true,
                        message: "Введите название"
                      }
                    ]}
                  >
                    <Select
                      options={positionsOptions}
                      onSearch={debounce(onPositionSearch, 500)}
                      onBlur={onSelectBlur}
                      showSearch
                      notFoundContent={isLoading ? <Spin size="small" /> : null}
                      onChange={(value) => onPositionChange(value, name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                className: "withInputs"
              },
              {
                title: "Товарная категория",
                key: "category_id",
                render: ({ key, name }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "category_id"]}
                    style={{ width: 350 }}
                    rules={[
                      {
                        required: true,
                        message: "Введите товарную категорию"
                      }
                    ]}
                  >
                    <Select
                      options={applicationPositionCategoryIdOptions}
                      showSearch
                      onChange={() => onClearResourceCode(name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                className: "withInputs"
              },
              {
                title: "Кол-во",
                key: "unit_amount",
                render: ({ key, name }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "unit_amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите кол-во"
                      },
                      {
                        pattern: new RegExp(/^\d*([.,]\d+)?$/),
                        message: "Введите корректное число"
                      }
                    ]}
                  >
                    <Input
                      onChange={(value) =>
                        onUnitAmountChange(value?.target?.value, name)
                      }
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 104,
                className: "withInputs"
              },
              {
                title: "Ед. изм.",
                key: "unit_type_id",
                render: ({ key, name }: FormListFieldData) => (
                  <Form.Item
                    key={key}
                    name={[name, "unit_type_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Введите ед. изм."
                      }
                    ]}
                  >
                    <Select
                      options={applicationPositionUnitTypeIdOptions}
                      onChange={() => onClearResourceCode(name)}
                      size="large"
                    />
                  </Form.Item>
                ),
                minWidth: 104,
                className: "withInputs"
              },
              {
                title: "Комментарий",
                key: "position_comment",
                render: ({ key, name }: FormListFieldData) => (
                  <Form.Item key={key} name={[name, "position_comment"]}>
                    <Input size="large" />
                  </Form.Item>
                ),
                minWidth: 220,
                className: "withInputs"
              },
              {
                key: "remove",
                render: ({ name }: FormListFieldData) => (
                  <Button
                    onClick={() => remove(name)}
                    icon={<MinusOutlined />}
                    size="large"
                  />
                ),
                minWidth: 64
              }
            ];

            return (
              <TableWrapper>
                {!!fields?.length && (
                  <TableScrollWrapper>
                    <Table
                      dataSource={fields}
                      columns={columns}
                      pagination={false}
                    />
                  </TableScrollWrapper>
                )}

                <Button onClick={() => add()} icon={<PlusOutlined />} />

                {!fields?.length && (
                  <Empty description="Пока нет ни одной позиции" />
                )}
              </TableWrapper>
            );
          }}
        </Form.List>
      </Form>

      <CTABlock>
        <NavButton
          type="primary"
          form="application_edit_positions"
          htmlType="submit"
        >
          Далее
        </NavButton>
        <NavButton onClick={onPrevStep}>Назад</NavButton>
      </CTABlock>
    </>
  );
};
