import { TPositionFromDict } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getPosition = (
  data: TGetPositionRequest
): RequestPromise<TGetPositionResponse> => {
  return apiRequest.get(`/position`, {
    params: data
  });
};

export type TGetPositionRequest = {
  search: string;
};

export type TGetPositionResponse = TPositionFromDict[];
