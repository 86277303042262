import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AlertWrapper, OfferWrapper } from "./OfferForSeller.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { userSelectors } from "store/user";
import { Alert, Button } from "antd";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { EFileType, TOfferForSellerRequest } from "types";
import { getFilesByObject } from "store/files/byObject/thunk";
import { OfferData, OfferEditForm } from "components/common/redesign";
import {
  offerForSellerByIdActions,
  offerForSellerByIdSelectors
} from "store/offersForSeller/byId";
import {
  offerForSellerUpdateActions,
  offerForSellerUpdateSelectors
} from "store/offersForSeller/update";
import {
  offerForSellerUpdateStatusActions,
  offerForSellerUpdateStatusSelectors
} from "store/offersForSeller/update_status";
import {
  ApplicationForSellerByIdActions,
  applicationForSellerByIdSelectors
} from "store/applicationsForSeller/byId";
import { getOfferForSellerById } from "store/offersForSeller/byId/thunk";
import { getApplicationForSellerById } from "store/applicationsForSeller/byId/thunk";
import { updateOfferForSeller } from "store/offersForSeller/update/thunk";
import { updateOfferForSellerStatus } from "store/offersForSeller/update_status/thunk";

export const OfferForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user, isAdmin } = useAppSelector(userSelectors.getState);
  const { offerForSeller } = useAppSelector(
    offerForSellerByIdSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    offerForSellerUpdateSelectors.getState
  );
  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForSellerUpdateStatusSelectors.getState
  );
  const { application } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );

  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { files } = useAppSelector(filesByObjectSelectors.getState);

  const { offer_id } = useParams<{ offer_id: string }>();

  const sellerEntity = useMemo(
    () =>
      user?.entities?.find(
        (entity) =>
          offerForSeller?.seller_entity?.entity_id === entity?.entity?.entity_id
      ),
    [offerForSeller?.seller_entity?.entity_id, user?.entities]
  );

  const canUpdate = useMemo(
    () =>
      (sellerEntity?.role_id && sellerEntity?.role_id >= 2) ||
      (sellerEntity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
      isAdmin,
    [
      isAdmin,
      sellerEntity?.entity?.cabinet_id,
      sellerEntity?.role_id,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id
    ]
  );

  const entitiesForOffer = useMemo(
    () =>
      user?.entities
        ?.filter(
          (entity) =>
            entity?.role_id >= 2 ||
            (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
              user?.cabinet_role_id === 2)
        )
        .map((entity) => entity?.entity),
    [user]
  );

  const getOffer = useCallback(
    (offer_id: number) => {
      dispatch(getOfferForSellerById(offer_id));
    },
    [dispatch]
  );
  const getApplication = useCallback(
    (application_id: number) => {
      dispatch(getApplicationForSellerById(application_id));
    },
    [dispatch]
  );
  const getFiles = useCallback(
    (offer_id: number) => {
      dispatch(
        getFilesByObject({
          id: offer_id,
          type: EFileType.OFFER
        })
      );
    },
    [dispatch]
  );

  const getAccounts = useCallback(
    (cabinet_id: number) => {
      dispatch(getAccountsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TOfferForSellerRequest) => {
      dispatch(updateOfferForSeller(values))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getOffer(Number(offer_id));
        });
    },
    [dispatch, getOffer, offer_id, onCancelEdit]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForSeller &&
        dispatch(
          updateOfferForSellerStatus({
            offer_id: offerForSeller?.offer_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getOffer(Number(offer_id));
          });
    },
    [dispatch, getOffer, offerForSeller, offer_id]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  useEffect(() => {
    offer_id && getOffer(Number(offer_id));
  }, [getOffer, offer_id]);
  useEffect(() => {
    offerForSeller && getApplication(offerForSeller?.application_id);
  }, [getApplication, offerForSeller]);
  useEffect(() => {
    offerForSeller && getFiles(offerForSeller?.offer_id);
  }, [getFiles, offerForSeller]);
  useEffect(() => {
    !accounts && user && getAccounts(user?.cabinet?.cabinet_id);
  }, [accounts, getAccounts, user]);

  useEffect(() => {
    return () => {
      dispatch(offerForSellerUpdateStatusActions.clearState());
      dispatch(offerForSellerUpdateActions.clearState());
      dispatch(offerForSellerByIdActions.clearState());
      dispatch(ApplicationForSellerByIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return offerForSeller ? (
    isEditing ? (
      <OfferEditForm
        offer={offerForSeller}
        application={application}
        entitiesForOffer={entitiesForOffer}
        user={user}
        accounts={accounts}
        onSubmit={onUpdate}
        isLoading={updateIsLoading}
        error={updateError}
        onCancel={onCancelEdit}
      />
    ) : (
      <OfferWrapper>
        <OfferData
          offer={offerForSeller}
          application={application}
          buyerEntity={application?.buyer_entity}
          files={files}
          getFiles={() => getFiles(offerForSeller?.offer_id)}
          canEditFiles={canUpdate}
          onEdit={onEdit}
          onGoBack={onGoBack}
          withEdit={canUpdate}
          withStatusChange={canUpdate}
          onUpdateStatus={onUpdateStatus}
          statusIsLoading={statusIsLoading}
          statusError={statusError}
          forSeller
          withMessages
        />
      </OfferWrapper>
    )
  ) : (
    <AlertWrapper>
      <Alert message="Данные по предложению отсутствуют" showIcon />

      <Button onClick={onGoBack}>Назад</Button>
    </AlertWrapper>
  );
};
